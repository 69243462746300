<template>
  <v-form
    ref="form"
    class="billing-details-form"
    lazy-validation
    @submit.prevent="submitForm()"
  >
    <!-- eslint-disable vue-i18n/no-dynamic-keys -->
    <template v-for="{ key, labelKey } of BILLING_DETAIL_LABELS">
      <component
        :is="key === 'state' ? 'v-autocomplete' : 'v-text-field'"
        :key="key"
        v-model="formData[key]"
        :items="countryCodes || []"
        item-text="value"
        item-value="id"
        :label="$t(labelKey)"
        :rules="rules[key] || []"
        :error="Boolean(errors[key])"
        :messages="errors[key] || []"
        :data-cy="`input-${key}`"
        :loading="contryCodesLoading"
        return-object
        validate-on-blur
        @input="resetError(key)"
      >
        <template #item="{ item }">
          <span :data-cy="`state-item-${item.value}`">{{ item.value }}</span>
        </template>
      </component>
      <core-element-info-message
        v-if="key === 'vatNumber'"
        :key="`${key}Info`"
        class="text-left"
        :message="$t('billing.euCompaniesWarning')"
      />
    </template>
    <core-element-errors-message
      :show-errors="showApiErrors"
      :errors="errors"
    />
    <v-btn
      rounded
      color="primary"
      data-cy="save-button"
      type="submit"
      :loading="loading"
    >
      {{ $t('save') }}
    </v-btn>
  </v-form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { VTextField, VAutocomplete } from 'vuetify/lib'
import { validateEmail } from '@/utils/validations'
import { BILLING_DETAIL_LABELS } from '@/constants/app'

export default {
  components: { VTextField, VAutocomplete },
  props: {
    clientId: {
      type: [String, Number],
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    resetForm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      BILLING_DETAIL_LABELS,
      formData: {}
    }
  },
  computed: {
    ...mapState({
      errors: state => state.billingDetails.errors,
      loading: state => state.billingDetails.loading,
      showApiErrors: state => state.billingDetails.showApiErrors,
      countryCodes: state => state.countryCodes.countryCodes,
      countryCodesLoading: state => state.countryCodes.loading
    }),
    ...mapGetters('client', ['getBillingDetailDataById']),
    billingDetails () {
      return this.getBillingDetailDataById(this.clientId)
    },
    rules () {
      return {
        email: [
          v => !!v || this.$i18n.t('errors.fieldIsRequired'),
          v => validateEmail(v) || this.$i18n.t('errors.wrongEmailFormat')
        ],
        company: [v => !!v || this.$i18n.t('errors.fieldIsRequired')],
        state: [v => !!v || this.$i18n.t('errors.fieldIsRequired')],
        vatNumber: [v => !!v || this.$i18n.t('errors.fieldIsRequired')]
      }
    }
  },
  watch: {
    resetForm (val, oldVal) {
      if (val && val !== oldVal) {
        this.$refs.form.resetValidation()
        this.formData = this.getDefaultFormData()
      }
    }
  },
  async created () {
    this.formData = this.getDefaultFormData()
    await this.getCountryCodesIfNeed()
  },
  methods: {
    ...mapMutations('billingDetails', ['setErrors']),
    ...mapActions('countryCodes', ['getCountryCodesIfNeed']),
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    submitForm () {
      if (this.$refs.form.validate()) {
        this.setErrors({})
        this.onSubmit(this.formData)
      }
    },
    getDefaultFormData () {
      if (!this.billingDetails) {
        return {}
      }

      return BILLING_DETAIL_LABELS.reduce((acc, { key }) => ({
        ...acc,
        [key]: this.billingDetails[key]
      }), {})
    },
    resetError (key) {
      if (this.errors[key]) {
        const newErrors = this.errors
        delete newErrors[key]
        this.setErrors(newErrors)
      }
    }
  }
}
</script>

<style>
.billing-details-form {
  max-width: 500px;
  margin: auto;
  margin-top: 16px;
  text-align: center;
}
</style>
